<template>
  <div class="kecheng">
    <div class="dis_flex_between">
      <el-radio-group
        class="colleges-redRadio"
        v-model="search.IsPurchase"
        @change="btnClick"
      >
        <el-radio-button :label="1">套餐赠送</el-radio-button>
        <el-radio-button :label="0">单独购买</el-radio-button>
      </el-radio-group>
      <el-radio-group
        class="redRadio"
        v-model="search.isSingle"
        @change="searchClick"
        v-show="search.IsPurchase == 1"
      >
        <el-radio :label="0">资料包</el-radio>
        <el-radio :label="1">资料</el-radio>
      </el-radio-group>
    </div>
    <!-- 学习资料 -->
    <div class="content">
      <!-- 学习资料 -->
      <div class="list-two" v-if="list && list.length > 0">
        <div class="item" v-for="(item, index) in list" :key="index">
          <img
            v-if="search.isSingle == 0"
            class="item-img"
            src="@/assets/img/homeSeventh/dataIcon.png"
          />
          <img
            v-if="search.isSingle == 1"
            class="file-img"
            src="@/assets/img/homeSeventh/dataFile.png"
          />
          <div class="item-content">
            <div class="item-text elp">{{ item.name }}</div>
            <div class="item-tips">
              <div class="tips-text" v-if="search.isSingle == 0">
                共{{ item.num ? item.num : 0 }}份资料
              </div>
              <div
                v-if="search.isSingle == 0 && search.IsPurchase == 1"
                class="m-r-4 m-l-4"
              >
                /
              </div>
              <div v-if="search.IsPurchase == 1" class="tips-text">
                {{ item.className }}
              </div>
            </div>
          </div>
          <div class="colleges-learn fles-shrink" @click="toMaterialPage(item)">
            查看资料
          </div>
        </div>
      </div>
      <!-- 暂无课程 -->
      <div class="nothing" v-if="!list || list.length == 0">
        <div class="nothing-text">暂无内容哦~</div>
      </div>
      <el-pagination
        class="pagination colleges-pagination"
        :total="total"
        :current-page="pageNum"
        :page-sizes="[8, 16, 24, 40]"
        :page-size="pageSize"
        :pager-count="5"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        background
        layout="total, sizes, prev, pager, next"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { DataClient } from "@/api/dataClient";
let dataClient = new DataClient();
import { getInfo } from "@/api/cookies";

export default {
  components: {},

  data() {
    return {
      list: {},
      search: {
        IsPurchase: 1, // 1 赠送  0 购买
        isSingle: 0, // 0资料包 1 资料
      },
      pageSize: 8,
      pageNum: 1,
      total: 0,
      userInfo: null,
    };
  },
  created() {
    this.userInfo = getInfo();
    this.getList();
  },
  methods: {
    // 获取知识套餐列表
    getList() {
      if (this.search.IsPurchase == 1) {
        this.getMyClassDocPackageList();
      } else {
        dataClient
          .getMyDocPackageList(
            this.pageSize,
            this.pageNum,
            this.search.type ? this.search.type : undefined,
            this.userInfo ? this.userInfo.id : undefined
          )
          .then((res) => {
            this.list = res.rows;
            this.total = res.total;
            if (res.code) {
              this.list = [];
              this.total = 0;
            }
          });
      }
    },
    /* 获取赠送的资料/资料包 */
    getMyClassDocPackageList() {
      let that = this;
      dataClient
        .getMyClassDocPackageList(
          this.pageSize,
          this.pageNum,
          this.search.type ? this.search.type : undefined,
          this.userInfo ? this.userInfo.id : undefined,
          this.search.isSingle
        )
        .then((res) => {
          this.list = res.rows;
          this.total = res.total;
          if (res.code) {
            this.list = [];
            this.total = 0;
          }
        });
    },

    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },
    /* ----------------------------------------------------- */
    isOk(val) {
      this.search = val;
      this.getList();
    },
    // 点击图片跳转到详情页
    checkClick(item) {
      this.$router.push(`/data/dataPackageDetail?packageId=${item.coursesId}`);
    },
    /* 赠送/购买筛选 */
    btnClick() {
      this.pageNum = 1;
      this.list = [];
      this.total = 0;
      this.search.isSingle = 0;
      this.getList();
    },
    /* 资料/资料包筛选 */
    searchClick() {
      this.pageNum = 1;
      this.list = [];
      this.total = 0;
      this.getList();
    },
    /* 点击查看资料 */
    toMaterialPage(item) {
      if (this.search.isSingle == 0) {
        this.$router.push({
          path: `/data/materialPage`,
          query: {
            id: item.coursesId,
            title: item.name,
            isPurchase: 0,
          },
        });
      } else {
        let data = {
          zlId: item.mid,
          xmId: item.kind,
          linkLocation: item.names,
          type: 1,
        };
        dataClient.downloadRecord(data);
        this.$router.push({
          path: "/VIP/sourcePage",
          query: {
            seeNum: item.seeNum,
            names: item.names,
            id: this.id,
            title: this.title,
            schoolId: this.schoolId,
            type: 2,
            IsPurchase: this.IsPurchase == 1 ? false : true,
          },
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.kecheng {
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  background: #ffffff;
  padding-bottom: 20px;
  .dis_flex_between {
    background: #fff;
    margin-top: 24px;
    .btnBox {
      display: flex;
      align-items: center;
      color: #666666;
      font-size: 14px;
      .btnItem {
        cursor: pointer;
      }
      .on {
        color: #ff5e51;
      }
    }
  }
  .content {
    min-height: calc(100% - 124px - 27px);
    overflow-y: scroll;
    .list-two {
      padding: 0px;
      background: #fff;
      .item {
        border-radius: 0px;
        border-bottom: 1px solid #eee;
        margin-bottom: 0px;
        padding: 24px 0px;
        height: auto;
        .item-img {
          width: 48px;
          height: 56px;
          flex-shrink: 0;
          margin-right: 12px;
        }
        .file-img {
          width: 40px;
          height: 40px;
          flex-shrink: 0;
          margin-right: 12px;
        }
        .item-content {
          width: 100%;
          .item-text {
            width: 500px;
            height: 16px;
            font-size: 16px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            line-height: 16px;
          }
          .item-tips {
            margin-top: 12px;
            display: flex;
            align-items: center;
            height: 12px;
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            line-height: 12px;
            .tips-text {
              height: 12px;
              font-size: 12px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #999999;
              line-height: 12px;
            }
          }
        }
      }
    }
  }
}

/deep/.redRadio {
  .is-checked {
    .el-radio__inner {
      border-color: #4a6af0;
      background: #4a6af0;
    }
  }
}
//隐藏滚动条
::-webkit-scrollbar {
  display: none !important;
  scrollbar-width: none !important;
  -ms-overflow-style: none;
}
</style>
